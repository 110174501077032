import React, { Component } from 'react';

import UserDetails from '../userDetails/userDetails';
import Search from '../trackSearch/trackSearch';

import './header.css';

class Header extends Component {
  render = () => (
    <React.Fragment></React.Fragment>
  );
}

export default Header;
