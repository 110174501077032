import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loader from 'react-loader-spinner'

import { setModal } from '../../store/actions/uiActions';

import QueueEntry from './components/queueEntry';

import { Track } from 'react-spotify-api';

import './leftSection.css';

class LeftSection extends Component {


  render() {

    var newQueue = this.props.queue.slice(0, 10);
    while (newQueue.length < 10){
      newQueue.push('None');
    }

    return (
      <div className="left-section">
      {
        newQueue.map((item, key) =>
          item != 'None' ?  
          
          <Track id={item} key={key}>
            {(track, loading, error) => (track ? <div className="queueBox"><QueueEntry song={track.name} artist={track.artists[0].name} album={track.album.name} /></div> :  <div className="queueBox"><Loader type="ThreeDots" color="#00BFFF" height={30} width={30} /></div>)}
          </Track>
          :  <div className="queueBox" key={key}><Loader type="ThreeDots" color="green" height={30} width={30} key={key}/></div>
        )
      }
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setModal
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(LeftSection);
