import React, { Component } from 'react';

import { connect } from 'react-redux';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';


import Modal from '../../components/playlistModal/modal';

import defaultProfile from './images/profile.png';
import SongPlayer from '../../components/songsPlayer/songsPlayer';
import CoverArt from '../../components/trackCover/trackCover';
import Details from '../../components/songsPlayer/components/detailsSection';
import './mainSection.css';



class MainSection extends Component {


  render = () => {
    let name = this.props.user.display_name;
    let id = this.props.user.id;
    let img = this.props.user.images[0]
      ? this.props.user.images[0].url
      : defaultProfile;

    let main;
    if(this.props.seeds.length < 3){
      main =   <div className="now-playing">
                  <div className="prompt">
                    <p>Select 3 seed songs below to begin your groove. To select seed songs, paste track URI below.</p>
                    <div className="arrow-line">
                      
                    <div className="arrow bounce">
                      <div className="fa fa-arrow-down fa-2x" href="#"></div>
                    </div>
                    <div className="arrow bounce">
                      <div className="fa fa-arrow-down fa-2x" href="#"></div>
                    </div>
                    <div className="arrow bounce">
                      <div className="fa fa-arrow-down fa-2x" href="#"></div>
                    </div>
                    </div>
                  </div>
                </div>
    }
    else{
      main = <React.Fragment>
          <div className="now-playing">
            <div className="album-art">
              < CoverArt />
            </div>
          </div>
          <div className="player-section">
            <SongPlayer increment={this.props.increment}/>
          </div>
      </React.Fragment>
    }


    return (
      <div className="main-section" style={{width:'100%'}}>
        <div className="bck"></div>
        <div className="main-frame" style={{marginLeft:'300px', width:'100%', height:'100%', textAlign:'right'}}> 
        <img src ={'GrooveGuide.png'} alt="logo" style={{position:'absolute', top:'0px', right:'0px', width:'10%'}}></img>
      
        </div>
          
        { main }

        <Footer token={this.props.token} setSeeds={this.props.setSeeds}  reset={this.props.reset}/>
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    view: state.uiReducer.view
  };
  
};

export default connect(mapStateToProps)(MainSection);
