import React, { Component } from 'react'
import MaterialIcon from 'material-icons-react';



const rowStyle = {
    height: '100%',
    width: '300px'
}

const lineStyle = {
    lineHeight: '3.27vh',
    display: 'flex',
    justifyContent: 'space-between'
}

const floatRight ={
    fontSize: '15px',
    color: '#35E4E4',
    marginRight: '10px'
}

const floatRightX ={
    whiteSpace: 'no-wrap',
    fontSize: '15px',
    color: 'red',
    marginRight: '10px'
}
const floatLeft = {
    fontSize: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '80%',
    marginLeft: '5px',
    textAlign: 'left',
}

export default class queueEntry extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="queueRow" style={rowStyle}>
                <div className="queueElement" style={lineStyle}><MaterialIcon icon="music_note" color='white' /><div style={floatLeft}>{this.props.song}</div></div>
                <div className="queueElement" style={lineStyle}><MaterialIcon icon="person" color='white' /><div style={floatLeft}> {this.props.artist}</div></div>
                <div className="queueElement" style={lineStyle}><MaterialIcon icon="album" color='white' /><div style={floatLeft}> {this.props.album}</div></div>
            </div>
           
        )
    }
}
