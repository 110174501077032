import React, { Component } from 'react'

import './footer.css'
import Seed from './seed';

export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      seeds: ['None','None','None']
    };
    this.setSeed = this.setSeed.bind(this);
  }

  reset = () => {
    this.props.reset();
    this.setState({
      active: 0,
      seeds: ['None','None','None']
    })
  }
  
  setSeed = (id, uri) => {
    let newSeeds = this.state.seeds;
    newSeeds[id] = uri;
    this.setState({seeds: newSeeds});
    this.setState({active: this.state.active+1})
    if (this.state.active == 3){
      this.props.setSeeds(this.state.seeds);
    }
  }

  render() {
    return (
      <React.Fragment>

      <button onClick={this.reset} className="reset-button" style={{position:'absolute', top:'0px', right:'0px', width:'10%', marginRight:'10%'}}>New Session</button>
      <div className="footer" >
      <div>
        <Seed id={0} active={this.state.active} songs={this.state.songs} set={this.setSeed} token={this.props.token}/>
      </div>
      <div>
        <Seed id={1} active={this.state.active} songs={this.state.songs} set={this.setSeed} token={this.props.token} />
      </div>
      <div>        
        <Seed id={2} active={this.state.active} songs={this.state.songs} set={this.setSeed} token={this.props.token}/>
      </div>
    </div>
      </React.Fragment>
    )
  }
}

