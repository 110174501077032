import React, { Component } from 'react'

import './seed.css';
import axios from 'axios';
import MaterialIcon from 'material-icons-react';
import Loader from 'react-loader-spinner'


export default class seed extends Component {
    constructor(props){
        super(props);
        this.state={
            error: false,
            uri: 'None',
            songs: ['None','None','None']
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event){
        this.setState({error: false})
        event.preventDefault();
        let uri = event.target.uri.value;
        uri = uri.replace("spotify:track:","");
        //uri.replace("spotify:track:","");
        this.setState({uri: uri})
    
        //Make API call to fetch song, if it exists set uri in footer
        //Else, display error message
        axios({
            method: 'GET',
            url: 'https://api.spotify.com/v1/tracks/' + uri,
            headers: {
              'Authorization': 'Bearer ' + this.props.token,
              'Content-Type': 'application/json'
            }})
            .then(res => {
                    let songs = this.state.songs;
                    songs[this.props.id] = [res.data.name, res.data.artists[0].name, res.data.album.name];
                    this.setState({songs: songs});
                    this.props.set(this.props.id,uri);

            })
            .catch(error =>{
                this.setState({error: true})
            })

    }


    render() {
        let error;
        if(this.state.error == true){
            error = <div className="error">Error loading track, please try again</div>
        }
        else{
            error = <div className="error"></div>
        }

        if(this.props.id == this.props.active){
           return(
           <React.Fragment>
                <div className="seed-header">Seed Song {this.props.id+1}</div>
                <form onSubmit={this.handleSubmit}>      
                    <input name="uri" type="text" className="feedback-input" placeholder="Track URI" />   
                    <button type="submit" className="submit-btn">SUBMIT</button>
                    {error}
                </form> 
           </React.Fragment>
          );
        }
        else{
            if(this.props.active > this.props.id){
                return(
                    <React.Fragment>
                        <div className="seed-header">Seed Song {this.props.id+1}</div> 
                        <div className="seed-entry">
                            <div className="seed-row"><div className="float-left"><MaterialIcon icon="music_note" color='white' /></div>{this.state.songs[this.props.id][0]}</div>
                            <div className="seed-row"><div className="float-left"><MaterialIcon icon="person" color='white' /></div>{this.state.songs[this.props.id][1]}</div>
                            <div className="seed-row"><div className="float-left"><MaterialIcon icon="album" color='white' /></div>{this.state.songs[this.props.id][2]}</div>
                        </div>
                    </React.Fragment>
                );
            }
            else{
                return(
                    <React.Fragment>
                        <div className="seed-header">Seed Song {this.props.id+1}</div> 
                        <Loader type="ThreeDots" color="green" height={50} width={50} style={{marginTop: '50px'}}/>
                    </React.Fragment>
                );
            }
                 
    
        }
    }
}
