import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import './App.css';

import { setToken } from './store/actions/sessionActions';
import { fetchUser } from './store/actions/userActions';

import Spinner from './components/spinner/spinner';
import LeftSection from './containers/leftSection/leftSection';
import MainSection from './containers/mainSection/mainSection';

import Login from './spotify/login';
import WebPlaybackReact from './spotify/webPlayback';

import {SpotifyApiContext, Playlist} from 'react-spotify-api';

window.onSpotifyWebPlaybackSDKReady = () => {};

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      playerLoaded: false,
      queue: [],
      queue2: [],
      seeds: [],
      library: [],
      sessionHistory: [],
      nowPlaying: 'None',
      playlistId: "None",
      user: "None",
      device: 'None',
      state_change_count: 0
    };
    this.setSeeds = this.setSeeds.bind(this);
    this.reset = this.reset.bind(this);
  }

  reset = () => {
    this.setState({
      queue: [],
      queue2: [],
      seeds: [],
      sessionHistory: [],
      nowPlaying: "None",
      playlistId: "None"
    })
  }


  setSeeds = (seeds) => {
    this.setState({seeds: seeds});
    //Set queue after seeds have been set
    var seed_string = seeds[0].replace("spotify:track:","") + ',' + seeds[1].replace("spotify:track:","") + ',' + seeds[2].replace("spotify:track:","")
    var url = 'https://api.spotify.com/v1/recommendations?limit=100&seed_tracks=' + seed_string;
    axios({
      method: 'GET',
      url: url,
      headers: {
        'Authorization': 'Bearer ' + this.state.token,
        'Content-Type': 'application/json'
      }})
      .then(res => {
        var tracks = res.data.tracks;
        var queue = [];
        var queue2 = [];
        tracks.map(function(track,i){
          var uri = track.uri;
          queue2.push(uri);
          queue.push(uri.replace("spotify:track:",""));
        });
        this.setState({
          queue: queue,
          queue2: queue2
        });
        let data = {
          uris: this.state.queue2
        }
        axios({
          method: 'PUT',
          url: 'https://api.spotify.com/v1/me/player/play?device_id=' + this.state.device,
          data: data,
          headers: {
            'Authorization': 'Bearer ' + this.state.token,
            'Content-Type': 'application/json'
          }})
          .then(res => {
            console.log("Changed playback state");
            this.setState({nowPlaying:this.state.queue2[0]})
            //this.incrementQueue();
          })
      })

  }
  
  // Removes first element from queues (both URI and trackID)
  incrementQueue = () => {
    
      this.setState({
        queue: this.state.queue.slice(1),
        queue2: this.state.queue2.slice(1)
      })
    
  }


  componentDidMount() {
    const token = Login.getToken();
    if (!token) {
      Login.logInWithSpotify();
    } else {
      this.setState({ token: token });
      this.props.setToken(token);
      this.props.fetchUser();
    }
  } //End of ComponentDidMount



  render() {

    let webPlaybackSdkProps = {
      playerName: 'Groove Guide',
      playerInitialVolume: 1.0,
      setActiveAppDevice: id => {
        this.setState({device: id})

      },
      onStateChange: (event) => {
        console.log(event.position);
        if(event.position == 0){
          if(this.state.state_change_count == 0){
            this.setState({state_change_count: this.state.state_change_count + 1})
          }
          else{
            this.setState({state_change_count: 0});
            this.incrementQueue();
            console.log("incrementing queue");
          }
        }
      },
      playerRefreshRateMs: 1000,
      playerAutoConnect: true,
      onPlayerRequestAccessToken: () => this.state.token,
      onPlayerLoading: () => {},
      onPlayerWaitingForDevice: () => {
        this.setState({ playerLoaded: true });
      },
      onPlayerError: e => {
        console.log(e);
      },
      onPlayerDeviceSelected: () => {
        this.setState({ playerLoaded: true });
      },
    };

    return (
      <SpotifyApiContext.Provider value={this.state.token}>
  
      <div className="app">
        <WebPlaybackReact {...webPlaybackSdkProps}>
          <Spinner loading={!this.state.playerLoaded}>
            <LeftSection queue={this.state.queue} token={this.state.token}/>
          <MainSection reset={this.reset} seeds={this.state.seeds} increment={this.incrementQueue} token={this.state.token} setSeeds={this.setSeeds}/>
          </Spinner>
        </WebPlaybackReact>
      </div>
      </SpotifyApiContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.sessionReducer.token
  };
};

const mapDispatchToProps = dispatch => ({
  setToken: token => dispatch(setToken(token)),
  fetchUser: user => dispatch(fetchUser())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
